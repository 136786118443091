import React, { useContext } from 'react';
import { Container } from 'components/common';

import dreamer from 'assets/illustrations/dreamer.svg'
import contact from 'assets/illustrations/contact.svg'
import { Wrapper, Details, Thumbnail } from './styles';
import ContactForm from './ContactForm';
import {ThemeContext} from "../../../providers/ThemeProvider";

export const Contact = () => {
    const {theme} = useContext(ThemeContext);

    return (
        <Wrapper as={Container} id="contact">
            <Details>
                <ContactForm/>
            </Details>
            <Thumbnail>
                <img src={theme === 'light' ? contact : dreamer}
                     alt="I’m Juan/OmniWired and I’m an engineer!"/>
            </Thumbnail>
        </Wrapper>
    );
}
