import React, {useContext} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {ThemeContext} from 'providers/ThemeProvider';
import {Button, Container} from 'components/common';
import dev from 'assets/illustrations/skills.svg';
import night from 'assets/illustrations/working-late.svg';
import {Details, SkillsWrapper, Thumbnail, Wrapper} from './styles';

export const Skills = () => {
    const {theme} = useContext(ThemeContext);

    return (
        <Wrapper id="about">
            <SkillsWrapper as={Container}>
                <Thumbnail>
                    <img src={theme === 'light' ? dev : night} alt="I’m Juan/OmniWired and I’m an engineer!"/>
                </Thumbnail>
                <Details theme={theme}>
                    <h1>More about me</h1>
                    <h3>
                        Technology leader with experience in:
                    </h3>
                    <ul>
                        <li>Software Development</li>
                        <li>API development</li>
                        <li>Proof of concepts</li>
                        <li>Technical design and creative solutions.</li>
                    </ul>
                    <p>
                        I get a sense of personal satisfaction for growing other people. Mentoring, leading, managing.
                    </p>
                    <h3>Working with me you can expect:</h3>
                    <ul>
                        <li>autonomy</li>
                        <li>good judgment</li>
                        <li>quality</li>
                        <li>honesty</li>
                        <li>consistent results</li>
                    </ul>

                    <Button as={AnchorLink} href="#contact">
                        Hire me
                    </Button>
                </Details>
            </SkillsWrapper>
        </Wrapper>
    );
};
