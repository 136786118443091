import React from 'react';
import {Button, Container} from 'components/common';

import {Wrapper} from './styles';
import './logos.css';
import AnchorLink from "react-anchor-link-smooth-scroll";
import {Link} from "gatsby";

export const Logos = () => {

    return (
        <Wrapper as={Container} id="projects">
            <h2>Worked with</h2>
            <article className="projects">
                <div className="slider">
                    <div className="container">
                        <div className="slide x"></div>
                        <div className="slide y"></div>
                        <div className="slide z"></div>
                    </div>
                    <div className="shadow"></div>
                </div>
                <div className="logos-text">
                    <h3>
                        Worked on prestigious brands, such as:
                    </h3>
                    <ul>
                        <li>
                            The 20th Century FOX (Movies)
                        </li>
                        <li>
                            Disney
                        </li>
                        <li>
                            FOX Broadcasting Company (TV)
                        </li>
                    </ul>
                    <p>
                        I love when I can see my work making a direct impact on people's lives. Furthermore when I can
                        see how humble beginnings turn into successful projects.
                    </p>
                    {/*<Button as={Link} href="/cv/CV_Combetto.pdf">*/}
                    {/*    Download my CV*/}
                    {/*</Button>*/}
                    {/*<Button as={Link} href="/portfolio">*/}
                    {/*    Portfolio*/}
                    {/*</Button>*/}
                </div>
            </article>
        </Wrapper>
    );
};
